<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4>NBCS Alert Recipients</h4>
                    </div>
                    <div class="card-body">

                        <table class="table">
                            <thead class="thead-primary">
                                <tr>
                                    <th>Name</th>
                                    <th>Telephone</th>
                                    <th>
                                        <button class="btn btn-sm btn-success float-end" @click="addRecipientModal.show()">New Recipient</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="recipient in recipients" :key="recipient.id">
                                    <td>{{ recipient.name }}</td>
                                    <td>{{ recipient.telephone }}</td>
                                    <td>
                                        <button class="btn btn-danger btn-sm" @click="deleteRecipient(recipient.id)">
                                            Remove
                                        </button>

                                        <button class="btn btn-primary btn-sm m-l-sm" @click="currentRecipient = recipient; name = recipient.name; telephone = recipient.telephone; editRecipientModal.show()">
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="!loading && recipients.length < 1">
                                    <td colspan="3" class="text-center">No recipients found</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="3" class="text-center">Loading...</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Add Recipient Modal -->
    <div class="modal fade" id="addRecipientModal" tabindex="-1" aria-labelledby="successModalTitle" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content p-h-xxl p-v-xxl">
                <div class="modal-title flex-center">
                    <h3 class="m-r-sm"> ADD RECIPIENT </h3>
                </div>
            
                <div class="row flex-col">
                    <div class="row">
                        <div class="col-md-12 m-b-lg">
                            <label for="name" class="form-label">Recipient name <span class="text-danger">*</span> </label>
                            <input type="text" name="name" class="form-control" id="name" v-model="name" required/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 m-b-lg">
                            <label for="telephone" class="form-label">Recipient telephone <span class="text-danger">*</span> - Please make sure to include the area code (e.g. +44) </label>
                            <input type="tele" name="telephone" class="form-control" id="telephone" v-model="telephone" required/>
                        </div> 
                    </div>
                    <div class="">
                        <button class="btn bg-success text-white" type="submit" @click="addRecipient"> 
                            <i class="material-icons" v-show="!adding"> add </i> 
                            <span v-show="adding" class="spinner-border spinner-border-sm m-r-xxs"></span>
                            Add Recipient
                        </button>
                    </div>
                </div>  
            </div>
        </div>
    </div>

    <!-- Edit Recipient Modal -->
    <div class="modal fade" id="editRecipientModal" tabindex="-1" aria-labelledby="successModalTitle" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content p-h-xxl p-v-xxl">
                <div class="modal-title flex-center">
                    <h3 class="m-r-sm"> EDIT RECIPIENT </h3>
                </div>
            
                <div class="row flex-col">
                    <div class="row">
                        <div class="col-md-12 m-b-lg">
                            <label for="name" class="form-label">Recipient name <span class="text-danger">*</span> </label>
                            <input type="text" name="name" class="form-control" id="name" v-model="name" required/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 m-b-lg">
                            <label for="telephone" class="form-label">Recipient telephone <span class="text-danger">*</span> - Please make sure to include the area code (e.g. +44) </label>
                            <input type="tele" name="telephone" class="form-control" id="telephone" v-model="telephone" required/>
                        </div> 
                    </div>
                    <div class="">
                        <button class="btn bg-success text-white" type="submit" @click="updateRecipient"> 
                            <i class="material-icons" v-show="!adding"> add </i> 
                            <span v-show="adding" class="spinner-border spinner-border-sm m-r-xxs"></span>
                            Update Recipient
                        </button>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '../../services/auth-header';

export default {
    name: 'Recipients',
    data(){
        return {
            loading: true,
            recipients: [],
            addRecipientModal: null,
            name: null,
            telephone: null,
            adding: false,
            editRecipientModal: null,
            currentRecipient: null
        }
    },
    mounted(){
        this.addRecipientModal = new bootstrap.Modal(document.getElementById('addRecipientModal'));
        this.editRecipientModal = new bootstrap.Modal(document.getElementById('editRecipientModal'));
        this.getRecipients();
    },
    methods: {
        getRecipients(){
            this.loading = true;
            axios.get(`https://api.varsanpr.com/api/nbcs/recipients`, { headers: authHeader() })
            .then(response => {
                this.recipients = response.data.recipients;
                this.loading = false;
            })
            .catch(error => {
                console.log(error);
            })
        },
        deleteRecipient(id){
            axios.delete(`https://api.varsanpr.com/api/nbcs/recipients`, {
                headers: authHeader(),
                data: {
                    id: id
                }
            })
            .then(response => {
                if(response.data.success){
                    this.$success("Recipient deleted successfully");
                    this.getRecipients();
                }else{
                    this.$error("Failed to delete recipient");
                }
            })
            .catch(error => {
                this.$error(error?.response?.data?.message || error?.message || error, error);
            })
        },
        addRecipient(){
            this.adding = true;
            axios.post(`https://api.varsanpr.com/api/nbcs/recipients`, {
                name: this.name,
                telephone: this.telephone
            }, {
                headers: authHeader()
            })
            .then(response => {
                if(response.data.success){
                    this.$success('Recipient added successfully');
                    this.name = null;
                    this.telephone = null;
                }else{
                    this.$error('Failed to add recipient');
                }
            })
            .catch(error => {
                this.$error(error?.response?.data?.message || error?.message || error, error);
            })
            .finally(() => {
                this.adding = false;
                this.addRecipientModal.hide();
                this.getRecipients();
            })
        },
        updateRecipient(){
            this.adding = true;
            axios.patch(`https://api.varsanpr.com/api/nbcs/recipients`, {
                id: this.currentRecipient.id,
                name: this.name,
                telephone: this.telephone
            }, {
                headers: authHeader()
            })
            .then(response => {
                if(response.data.success){
                    this.$success('Recipient updated successfully');
                    this.name = null;
                    this.telephone = null;
                    this.currentRecipient = null;
                }else{
                    this.$error('Failed to update recipient');
                }
            })
            .catch(error => {
                this.$error(error?.response?.data?.message || error?.message || error, error);
            })
            .finally(() => {
                this.adding = false;
                this.editRecipientModal.hide();
                this.getRecipients();
            })
        }
    }
}
</script>